import React from "react";
import { useLocalObservable } from "mobx-react-lite";
import { types, Instance, SnapshotIn, onSnapshot, flow } from "mobx-state-tree";
import log from "loglevel";
import {
  fetchProjectList,
  login,
  IUser,
  IProject,
  fetchProjectDetail,
  IProjectDetail,
  fetchDeskTimeProjects,
  IDeskTimeStatus,
  fetchStopProject,
  fetchStartProject,
} from "../api/desktime";

export interface ISnackMessage {
  // We can have more data here to be used on snackbar, like type (error/warning/info) or some other stuff like timeout ...
  message: string;
}

export const AppState = types
  .model("AppState", {
    snackMessage: types.array(types.frozen<ISnackMessage>()),
    loggedin: types.optional(types.boolean, false),
    user: types.maybeNull(types.frozen<IUser>()),
    remember: types.optional(types.boolean, false),
    projectLoadedAt: types.maybeNull(types.Date),
    projects: types.array(types.frozen<IProject>()),
    projectDetail: types.map(types.frozen<IProjectDetail[]>()),
    deskTimeStatus: types.maybeNull(types.frozen<IDeskTimeStatus>()),
  })
  .actions((self) => ({
    addSnackMessage(value: ISnackMessage) {
      self.snackMessage.push(value);
    },
    removeFirstSnackMessage() {
      if(self.snackMessage.length > 0){
        self.snackMessage.splice(0, 1);
      }
    },
  }))
  .actions((self) => {
    let fetchingProjectList = false;
    return {
      fetchProjectList: flow(function* () {
        if (!self.loggedin || fetchingProjectList || !self.user) return;
        try {
          fetchingProjectList = true;
          const projects: IProject[] = yield fetchProjectList(self.user.authId);
          self.projectLoadedAt = new Date();
          self.projects.clear();
          self.projects.push(...projects);
        } catch (err) {
          console.error(err);
          self.addSnackMessage({message: extractErrorMessage(err)});
        } finally {
          fetchingProjectList = false;
        }
      }),
    };
  })
  .actions((self) => {
    let fetchingDesktimeProjects = false;
    return {
      fetchDesktimeProjects: flow(function* () {
        if (!self.loggedin || fetchingDesktimeProjects || !self.user) return;
        try {
          fetchingDesktimeProjects = true;
          const projects: IDeskTimeStatus = yield fetchDeskTimeProjects(
            self.user.authId
          );
          self.deskTimeStatus = projects;
        } catch (err) {
          console.error(err);
          self.addSnackMessage({message: extractErrorMessage(err)});
        } finally {
          fetchingDesktimeProjects = false;
        }
      }),
    };
  })
  .actions((self) => {
    const fetching: { [key: string]: boolean } = {};
    return {
      fetchProjectDetail: flow(function* (projectId: string) {
        if (!self.loggedin || fetching[projectId] || !self.user) return;
        try {
          fetching[projectId] = true;
          const projectDetail: IProjectDetail[] = yield fetchProjectDetail(
            self.user.authId,
            projectId
          );
          self.projectDetail.set(projectId, projectDetail);
        } catch (err) {
          console.error(err);
          self.projectDetail.delete(projectId);
          self.addSnackMessage({message: extractErrorMessage(err)});
        } finally {
          fetching[projectId] = false;
        }
      }),
      getProjectDetail: async (projectId: string) => {
        if (!self.loggedin || !self.user) return;
        try {
          const projectDetail: IProjectDetail[] = await fetchProjectDetail(
            self.user.authId,
            projectId
          );
          return projectDetail;
        } catch (err) {
          console.error(err);
          self.addSnackMessage({message: extractErrorMessage(err)});
        }
      },
    };
  })
  .actions((self) => {
    let fetching = false;
    return {
      startTask: flow(function* (projectName: string, taskName: string) {
        if (!self.loggedin || fetching || !self.user) return;
        try {
          fetching = true;
          const result = yield fetchStartProject(
            self.user.authId,
            projectName,
            taskName
          );
          console.log(result);
          self.fetchDesktimeProjects();
        } catch (err) {
          console.error(err);
          self.addSnackMessage({message: extractErrorMessage(err)});
        } finally {
          fetching = false;
        }
      }),
    };
  })
  .actions((self) => {
    let fetching = false;
    return {
      stopTask: flow(function* () {
        if (!self.loggedin || fetching || !self.user) return;
        try {
          fetching = true;
          yield fetchStopProject(self.user.authId);
          self.fetchDesktimeProjects();
        } catch (err) {
          console.error(err);
          self.addSnackMessage({message: extractErrorMessage(err)});
        } finally {
          fetching = false;
        }
      }),
    };
  })
  .actions((self) => ({
    refresh: async () => {
      await self.fetchProjectList();
      await self.fetchDesktimeProjects();
    },
  }))
  .actions((self) => {
    let loggingin = false;
    return {
      login: flow(function* (username: string, password: string) {
        if (self.loggedin || loggingin) return;
        try {
          loggingin = true;
          const user: IUser = yield login(username, password);
          self.loggedin = true;
          self.user = user;
        } catch (err) {
          console.error(err);
          self.addSnackMessage({message: extractErrorMessage(err)});
        } finally {
          loggingin = false;
        }
      }),
      logout() {
        self.loggedin = false;
        self.user = null;
        self.projects.clear();
        self.projectLoadedAt = null;
        self.projectDetail.clear();
        self.deskTimeStatus = null;
      },
    };
  });

export interface IAppState extends Instance<typeof AppState> {}
export interface IAppStateSnapshot extends SnapshotIn<typeof AppState> {}

function extractErrorMessage(err: any) {
  if(typeof err ==='string'){
    return err;
  }else if(err.message){
    return err.message;
  }
}

export const AuthStoreContext = React.createContext<IAppState | null>(null);

export const AppStateProvider: React.FC = ({ children }) => {
  const store = useLocalObservable(createStore);

  return (
    <AuthStoreContext.Provider value={store}>
      {children}
    </AuthStoreContext.Provider>
  );
};

export const useAppState = () => {
  const store = React.useContext(AuthStoreContext);
  if (!store) {
    throw new Error("useAuthStore must be used within StoreProvider");
  }
  return store;
};

export type EnvType = {
  // user: IUser;
};

export function createStore() {
  const injection: EnvType = {
    // user: process.env.NODE_ENV === "test" ? new MockUser() : new User(),
  };

  let store;
  try {
    store = AppState.create(loadStore(), injection);
  } catch (err) {
    log.warn("could not create the state, fallback to empty one.");
    log.warn(err);
    // Sentry.captureException(err);
    store = AppState.create(emptyStore(), injection);
  }
  onSnapshot(store, saveStore);

  return store;
}

export function createTestStore(snapshot: IAppStateSnapshot): IAppState {
  const injection: EnvType = {
    // user: new MockUser(),
  };

  const store = AppState.create(snapshot, injection);

  return store;
}

export const SNAPSHOT_KEY = "desk-invemo-app-store";

function loadStore(): IAppStateSnapshot {
  const snapshotStr =
    localStorage.getItem(SNAPSHOT_KEY) || sessionStorage.getItem(SNAPSHOT_KEY);

  try {
    if (!snapshotStr) return emptyStore();
    const snapshot = JSON.parse(snapshotStr) as IAppStateSnapshot;
    return snapshot;
  } catch (err) {
    log.warn("could not restore the app snapshot, fall back to empty one.");
    log.warn(err);
    // Sentry.captureException(err);
    return emptyStore();
  }
}

function saveStore(initSnapshot: IAppStateSnapshot) {
  const snapshot = JSON.parse(JSON.stringify(initSnapshot));
  if (snapshot.remember) {
    localStorage.setItem(SNAPSHOT_KEY, JSON.stringify(snapshot));
    sessionStorage.removeItem(SNAPSHOT_KEY);
  } else {
    sessionStorage.setItem(SNAPSHOT_KEY, JSON.stringify(snapshot));
    localStorage.removeItem(SNAPSHOT_KEY);
  }
}

// function removeLocalData() {
//   sessionStorage.removeItem(SNAPSHOT_KEY);
//   localStorage.removeItem(SNAPSHOT_KEY);
//   // caches?.delete('all');
// }

function emptyStore(): IAppStateSnapshot {
  return {};
}
