import { Button, Container, FormHelperText, Grid, makeStyles, Paper, TextField } from "@material-ui/core";
import React from "react";
import { useAppState } from "../../states/app-state";

const userStyles = makeStyles((theme) => ({
  loginContainer: {
    paddingTop: theme.spacing(5),
    '& .paper': {
      padding: theme.spacing(3),
    }
  }
}));

function Auth() {
  const classes = userStyles();
  const appState = useAppState();

  const [error, setError] = React.useState<any>(null);
  const handleLogin = async () => {
    try{
      setError(null);
      await appState.login(username, password);
    }catch(err){
      setError(err);
    }
  };

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  return (
    <Container maxWidth="xs" className={classes.loginContainer}>
      <Paper className="paper">
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              label="Username"
              placeholder="@invemo.com"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              type="Password"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleLogin}>Login</Button>
          </Grid>
          {error && <Grid item xs={12}>
            <FormHelperText error>{JSON.stringify(error)}</FormHelperText>
          </Grid>}
        </Grid>
      </form>
      </Paper>
    </Container>
  );
}

export default Auth;
