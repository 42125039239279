import React from "react";
import { observer } from "mobx-react-lite";
import Login from "../components/auth/Login";

const Auth: React.FC = observer(() => {
  return (
    <>
      <Login />
    </>
  );
});

export default Auth;
