import React, { Suspense } from "react";
import { CssBaseline, MuiThemeProvider, PaletteType } from "@material-ui/core";
import createAppTheme from "./theme";
import { AppStateProvider } from "./states/app-state";
import { CircularProgress, makeStyles } from "@material-ui/core";
import AppContainer from "./containers/AppContainer";

function App() {
  return (
    <ThemeTypeStateProvider>
      <AppContent />
    </ThemeTypeStateProvider>
  );
}

function AppContent() {
  const { type } = useThemeTypeState();
  const theme = createAppTheme(type);
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AppStateProvider>
        <Suspense fallback={<Loading />}>
          <AppContainer />
        </Suspense>
      </AppStateProvider>
    </MuiThemeProvider>
  );
}

export default App;

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.grey["900"],
  },
}));
export const Loading: React.FC = () => {
  const classes = useStyles();
  return (
    <div data-testid="loading" className={classes.loading}>
      <CircularProgress color="inherit" />
    </div>
  );
};

interface IThemeTypeState {
  type: PaletteType;
  setType: React.Dispatch<React.SetStateAction<PaletteType>>;
}
const ThemeTypeStoreContext = React.createContext<IThemeTypeState | null>(null);

const ThemeTypeStateProvider: React.FC = ({ children }) => {
  const [type, setType] = React.useState<PaletteType>("light");

  const store = {
    type,
    setType,
  };

  React.useEffect(() => {
    const savedThemeType = localStorage.getItem("amsa-theme-type");
    if (savedThemeType) {
      if (savedThemeType === "dark") {
        setType("dark");
      } else if (savedThemeType === "light") {
        setType("light");
      }
    }
  }, [setType]);

  return (
    <ThemeTypeStoreContext.Provider value={store}>
      {children}
    </ThemeTypeStoreContext.Provider>
  );
};

export const useThemeTypeState = () => {
  const store = React.useContext(ThemeTypeStoreContext);
  if (!store) {
    throw new Error(
      "useThemeTypeState must be used within ThemeTypeStateProvider"
    );
  }
  return store;
};
