import React from "react";
import { useAppState } from "../states/app-state";
import { observer } from "mobx-react-lite";
import Auth from "../pages/Auth";
import Scaffold from "./Scaffold";

const AppContainer: React.FC = observer(() => {
  const appState = useAppState();

  const { loggedin } = appState;

  // if (!loaded) {
  //   return <Loading />;
  // }

  if (!loggedin) {
    return <Auth />;
  }

  return (
    <Scaffold />
  );
});

export default AppContainer;
