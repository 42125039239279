const API_ADDRESS = 'https://desktime.invemo.com';

export interface IUser {
  authId: string;
}

export async function login(
  username: string,
  password: string
): Promise<IUser> {
  try{
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    const result = await fetch(`${API_ADDRESS}/login.cfm`, {
      method: "POST",
      body: formData,
    });
    if(result.status===200){
      const json = await result.json();
      if(json.errMessage){
        throw json.errMessage;
      }
      return json;
    }else{
      const body = await result.text();
      throw body;
    }
  }catch(err){
    console.error(err);
    throw err;
  }

}

export interface IProject {
  gid: number;
  name: string;
  resource_type: string;
}

export async function fetchProjectList(
  authId: string,
): Promise<IProject[]> {
  try{
    const formData = new FormData();
    formData.append('dtRequestType', 'projects');
    formData.append('authId', authId);

    const result = await fetch(`${API_ADDRESS}/dt_api.cfm`, {
      method: "POST",
      body: formData,
    });
    if(result.status===200){
      const json = await result.json();
      if(json.errMessage){
        throw json.errMessage;
      }
      return json.data;
    }else{
      const body = await result.text();
      throw body;
    }
  }catch(err){
    console.error(err);
    throw err;
  }

}

export interface IDeskTimeProject {
  task_title?: string;
  project_title?: string;
  project_id?: number;
  task_id?: number;
  duration?: number;
}

export interface IDeskTimeStatus {
  desktimeTime?: number;
  left?: string;
  isOnline?: boolean;
  arrived?: string;
  late?: boolean;
  projects?: IDeskTimeProject[];
  work_starts?: string;
  email?: string;
  name?: string;
  group?: string;
  onlineTime?: number;
  notes?: any[];
  groupId?: number;
  work_ends?: string;
  atWorkTime?: number;
  productiveTime?: number;
  profileUrl?: string;
  productivity?: number;
  beforeWorkTime?: number;
  activeProject?: IDeskTimeProject | [];
  id?: number;
  afterWorkTime?: number;
  offlineTime?: number;
  efficiency?: number;
  loadedAt: number;
}

export async function fetchDeskTimeProjects(
  authId: string,
): Promise<IDeskTimeStatus> {
  try{
    const formData = new FormData();
    formData.append('dtRequestType', 'desktimeProjects');
    formData.append('authId', authId);

    const result = await fetch(`${API_ADDRESS}/dt_api.cfm`, {
      method: "POST",
      body: formData,
    });
    if(result.status===200){
      const json = await result.json();
      if(json.errMessage){
        throw json.errMessage;
      }
      json.loadedAt = new Date().getTime();
      return json;
    }else{
      const body = await result.text();
      throw body;
    }
  }catch(err){
    console.error(err);
    throw err;
  }
}

export interface IProjectDetail {
  gid: any;
  name: string;
  resource_type: string;
}

export async function fetchProjectDetail(
  authId: string,
  projectId: string,
): Promise<IProjectDetail[]> {
  try{
    const formData = new FormData();
    formData.append('dtRequestType', 'projectDetail');
    formData.append('authId', authId);
    formData.append('projectId', projectId);

    const result = await fetch(`${API_ADDRESS}/dt_api.cfm`, {
      method: "POST",
      body: formData,
    });
    if(result.status===200){
      const json = await result.json();
      if(json.errMessage){
        throw json.errMessage;
      }
      return json.data;
    }else{
      const body = await result.text();
      throw body;
    }
  }catch(err){
    console.error(err);
    throw err;
  }
}

export async function fetchStartProject(
  authId: string,
  projectName: string,
  taskName: string,
): Promise<void> {
  try{
    const formData = new FormData();
    formData.append('dtRequestType', 'startTask');
    formData.append('authId', authId);
    formData.append('projectName', projectName);
    formData.append('taskName', taskName);

    const result = await fetch(`${API_ADDRESS}/dt_api.cfm`, {
      method: "POST",
      body: formData,
    });
    if(result.status===200){
      const json = await result.json();
      if(json.errMessage){
        throw json.errMessage;
      }
      return json;
    }else{
      const body = await result.text();
      throw body;
    }
  }catch(err){
    console.error(err);
    throw err;
  }
}
export async function fetchStopProject(
  authId: string,
): Promise<void> {
  try{
    const formData = new FormData();
    formData.append('dtRequestType', 'stopTask');
    formData.append('authId', authId);

    const result = await fetch(`${API_ADDRESS}/dt_api.cfm`, {
      method: "POST",
      body: formData,
    });
    if(result.status===200){
      const json = await result.json();
      if(json.errMessage){
        throw json.errMessage;
      }
      console.log(json)
      return json;
    }else{
      const body = await result.text();
      throw body;
    }
  }catch(err){
    console.error(err);
    throw err;
  }
}