import React from "react";
import { observer } from "mobx-react-lite";
import { Snackbar, makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useAppState } from "../states/app-state";

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
}));

const Notifier: React.FunctionComponent<IProps> = observer((props) => {
  const appStore = useAppState();

  const { snackMessage } = appStore;
  // console.log(`render in notifier with `, snackMessage)

  // const { t } = useTranslation();
  const classes = useStyles();

  const hide = React.useCallback(() => {
    appStore.removeFirstSnackMessage();
  }, [appStore]);

  React.useEffect(()=>{
    const timeout = setInterval(hide, 5000);
    return ()=>{
      clearInterval(timeout);
    }
  }, [hide]);

  if (snackMessage.length===0) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={true}
      autoHideDuration={6000}
      onClose={hide}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
      message={snackMessage[0].message}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={hide}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
});

interface IProps {}

export default Notifier;
