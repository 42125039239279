import React, { Suspense } from "react";
import {
  makeStyles,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  AppBar,
  IconButton,
  Toolbar,
  Divider,
} from "@material-ui/core";
import {
  Switch as RouteSwitch,
  Route,
  BrowserRouter as Router,
  Link,
} from "react-router-dom";
import Home from "../pages/Home";
import NoMatch from "../pages/NoMatch";
import Logout from "../pages/Logout";
import MenuIcon from "@material-ui/icons/Menu";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import clsx from "clsx";
import { Loading, useThemeTypeState } from "../App";
import LightIcon from "@material-ui/icons/Brightness7";
import DarkIcon from "@material-ui/icons/Brightness4";
import Notifier from "./Notifier";
import { InvemoLogo } from "../components/auth/InvemoLogo";

const drawerWidth = 300;

const useStyle = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
  },
  appBar: {
    background: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawer: {
    transition: `width 0.2s ${theme.transitions.easing.easeInOut}`,
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerClosed: {
    [theme.breakpoints.up("sm")]: {
      width: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    color: "white",
    background: theme.palette.primary.dark,
    "& a": {
      color: theme.palette.getContrastText(theme.palette.primary.dark),
      textDecoration: "none",
    },
    "& .MuiListItemIcon-root": {
      color: "white",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: "100vh",
    position: "relative",
  },
  copyRight: {
    position: "absolute",
    bottom: 0,
    left: theme.spacing(1),
    fontSize: 10,
  },
  version: {
    position: "fixed",
    bottom: 0,
    fontSize: 10,
    left: theme.spacing(0.5),
  },
}));

const Scaffold: React.FC = ({ children }) => {
  const classes = useStyle();

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen((v) => !v);
  };

  return (
    <div className={classes.root}>
      <Notifier />
      <Router>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <InvemoLogo/>
            </Typography>
            <ThemeSwitch />
          </Toolbar>
        </AppBar>
        <nav
          className={clsx(classes.drawer, !open && classes.drawerClosed)}
          aria-label="mailbox folders"
        >
          <Drawer
            anchor="left"
            open={open}
            variant={"persistent"}
            onClose={toggleDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Toolbar />
            <div>
              <Menu />
            </div>
            <Typography variant="body2" className={classes.version}>
              Version: {process.env.REACT_APP_VERSION}
            </Typography>
          </Drawer>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Suspense fallback={<Loading />}>
            <Routes />
          </Suspense>
          <Typography variant="body2" className={classes.copyRight}>
            ©2021 copy right.
          </Typography>
        </main>
      </Router>
    </div>
  );
};

export default Scaffold;

const Routes: React.FC = () => {

  return (
    <RouteSwitch>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="*">
        <NoMatch />
      </Route>
    </RouteSwitch>
  );
};

const Menu: React.FC = (): JSX.Element => {
  return (
    <>
      <Divider style={{ background: "#a0a0a0", margin: "0 10px" }} />
      <Link to="/logout">
        <ListItem button>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </Link>
    </>
  );
};

const ThemeSwitch = () => {
  const { type, setType } = useThemeTypeState();

  const toggleChange = () => {
    setType((t) => {
      if (t === "dark") {
        localStorage.setItem("amsa-theme-type", "light");
        return "light";
      } else {
        localStorage.setItem("amsa-theme-type", "dark");
        return "dark";
      }
    });
  };

  return (
    <IconButton onClick={toggleChange}>
      {type === "dark" && <LightIcon />}
      {type === "light" && <DarkIcon />}
    </IconButton>
  );
};
