import {
  CircularProgress,
  Container,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Box,
} from "@material-ui/core";
import { Observer, observer } from "mobx-react-lite";
import React from "react";
import {
  IDeskTimeProject,
  IDeskTimeStatus,
  IProject,
  IProjectDetail,
} from "../api/desktime";
import { useAppState } from "../states/app-state";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import CloudIcon from "@material-ui/icons/Cloud";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Refresh from "@material-ui/icons/Refresh";

const useStyles = makeStyles((theme) => ({
  home: {
    "& .task-title": {
      marginTop: theme.spacing(1),
    },
    "& .search": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    "& .tasks-container": {
      maxHeight: 450,
      overflow: "auto",
    },
    "& .project-card": {
      padding: theme.spacing(2),
      "& .project-title[data-active=true]": {
        color: theme.palette.type === "dark" ? "lime" : "green",
        display: "flex",
        alignItems: "center",
        "& .icon": {
          marginRight: theme.spacing(2),
        },
      },
      "& .project-actions": {
        display: "flex",
        alignItems: 'center',
        marginLeft: theme.spacing(4),
      },
    },
    "& .user-card": {
      padding: theme.spacing(2),
    },
    "& .user-title": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .user-actions": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

function Home() {
  const classes = useStyles();
  return (
    <Container maxWidth="md" className={classes.home}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <DesktimeProjects />
        </Grid>
        <Grid item xs={12} md={6}>
          <Projects />
        </Grid>
      </Grid>
    </Container>
  );
}

export default observer(Home);

function Projects() {
  const appState = useAppState();

  React.useEffect(() => {
    if (!appState.projectLoadedAt) {
      appState.fetchProjectList();
    }
  }, [appState]);

  const { projects } = appState;
  const [selectedProjectId, setSelectedProjectId] = React.useState<string>("");
  const project =
    selectedProjectId &&
    projects &&
    projects.find((p) => p.gid.toString() === selectedProjectId);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Observer>
          {() => {
            return (
              <TextField
                fullWidth
                select
                label="Project"
                value={selectedProjectId}
                onChange={(e) => setSelectedProjectId(e.target.value)}
              >
                <MenuItem value={""}>-</MenuItem>
                {projects.map((project) => (
                  <MenuItem key={project.gid} value={project.gid.toString()}>
                    {project.name}
                  </MenuItem>
                ))}
              </TextField>
            );
          }}
        </Observer>
      </Grid>
      {project && (
        <Grid item xs={12}>
          <Details project={project} />
        </Grid>
      )}
    </Grid>
  );
}

const Details = observer(({ project }: { project: IProject }) => {
  const appState = useAppState();

  const [loading, setLoading] = React.useState(false);
  const [details, setDetails] = React.useState<IProjectDetail[] | undefined>(
    undefined
  );

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const details = await appState.getProjectDetail(project.gid.toString());
      setDetails(details);
      setLoading(false);
    })();
  }, [appState, project, setDetails]);

  const [search, setSearch] = React.useState("");

  if (loading) {
    return <>Loading...</>;
  }
  if (!details) {
    return <>Wait</>;
  }

  const tasks = !search
    ? details
    : details.filter((t) => t.name.match(new RegExp(search, "ig")));

  return (
    <>
      <Typography className="task-title" variant="h5" color="textSecondary">
        Tasks:
      </Typography>
      <TextField
        className="search"
        label="Search"
        fullWidth
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Grid container spacing={2} className="tasks-container">
        {search && tasks.length === 0 && (
          <Typography>No task matched</Typography>
        )}
        {!search && tasks.length === 0 && (
          <Typography>No task defined yet</Typography>
        )}
        {tasks.length > 0 &&
          tasks.map((detail) => (
            <Grid
              key={detail.gid}
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>{detail.name}</Typography>
              {<StartTask projectName={project.name} taskName={detail.name} />}
            </Grid>
          ))}
      </Grid>
    </>
  );
});

const DesktimeProjects = observer(() => {
  const appState = useAppState();

  const { deskTimeStatus } = appState;

  React.useEffect(() => {
    if (!deskTimeStatus) {
      appState.fetchDesktimeProjects();
    }
  }, [appState, deskTimeStatus]);

  if (!deskTimeStatus) return <>Loading...</>;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UserCard status={deskTimeStatus} />
      </Grid>
      <Grid item xs={12}>
        <ActiveProject status={deskTimeStatus} />
      </Grid>
      <Grid item xs={12}>
        <ProjectsList status={deskTimeStatus} />
      </Grid>
    </Grid>
  );
});

function RefreshData() {
  const [loading, setLoading] = React.useState(false);
  const appState = useAppState();

  const handleRefresh = React.useCallback(async () => {
    setLoading(true);
    await appState.refresh();
    setLoading(false);
  }, [setLoading, appState]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      handleRefresh();
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [handleRefresh]);

  return (
    <>
      {!loading && (
        <IconButton onClick={handleRefresh}>
          <Refresh />
        </IconButton>
      )}
      {loading && <CircularProgress />}
    </>
  );
}

function StopTask() {
  const [loading, setLoading] = React.useState(false);
  const appState = useAppState();

  const handleStopTask = async () => {
    setLoading(true);
    await appState.stopTask();
    setLoading(false);
  };

  return (
    <>
      {!loading && (
        <IconButton color="inherit" onClick={handleStopTask}>
          <PauseCircleOutlineIcon />
        </IconButton>
      )}
      {loading && <CircularProgress />}
    </>
  );
}

function StartTask({
  projectName,
  taskName,
}: {
  projectName: string;
  taskName: string;
}) {
  const [loading, setLoading] = React.useState(false);
  const appState = useAppState();

  const handleStartTask = async () => {
    setLoading(true);
    await appState.startTask(projectName, taskName);
    setLoading(false);
  };

  return (
    <>
      {!loading && (
        <IconButton color="inherit" onClick={handleStartTask}>
          <PlayCircleOutlineIcon />
        </IconButton>
      )}
      {loading && <CircularProgress />}
    </>
  );
}

function UserCard({ status }: { status: IDeskTimeStatus }) {
  return (
    <Paper className="user-card">
      <div className="user-title">
        <Typography variant="h5" color="textSecondary">
          User
        </Typography>
        <div className="user-actions">
          <RefreshData />
          <a
            href="https://desktime.com/app/my"
            target="_blank"
            rel="noreferrer"
          >
            <IconButton>
              <OpenInNewIcon />
            </IconButton>
          </a>
          {status.isOnline && (
            <Box padding={1}>
              <CloudIcon />
            </Box>
          )}
          {!status.isOnline && (
            <Box padding={1}>
              <CloudOffIcon color="error" />
            </Box>
          )}
        </div>
      </div>
      <Typography variant="body1">{status.name}</Typography>
      <Typography variant="body1">{status.email}</Typography>
      <Typography variant="body1">Efficiency {status.efficiency}</Typography>
      <Typography variant="body1">
        Productivity {status.productivity}
      </Typography>
      <Typography variant="body1">
        Productive Time {formatSeconds(status.productiveTime)}
      </Typography>
      <Typography variant="body1">
        onlineTime {formatSeconds(status.onlineTime)}
      </Typography>
    </Paper>
  );
}

function ActiveProject({ status }: { status: IDeskTimeStatus }) {
  return (
    <>
      <Typography variant="h5" color="textSecondary">
        Active Project
      </Typography>
      {(!status.activeProject ||
        (Array.isArray(status.activeProject) &&
          status.activeProject.length === 0)) && (
        <Typography variant="body1">---</Typography>
      )}
      {status.activeProject && !Array.isArray(status.activeProject) && (
        <DesktimeProjectCard active project={status.activeProject} />
      )}
    </>
  );
}

function ProjectsList({ status }: { status: IDeskTimeStatus }) {
  return (
    <>
      <Typography variant="h5" color="textSecondary">
        Projects
      </Typography>
      {(!status.projects || status.projects.length === 0) && (
        <Typography variant="body1">---</Typography>
      )}
      {status.projects && status.projects.length > 0 && (
        <Grid container spacing={2}>
          {status.projects.map((project) => (
            <Grid
              key={
                (project.project_id ?? 0).toString() +
                (project.task_id ?? 0).toString()
              }
              item
              xs={12}
            >
              <DesktimeProjectCard project={project} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

function DesktimeProjectCard({
  project,
  active,
}: {
  project: IDeskTimeProject;
  active?: boolean;
}) {
  return (
    <Paper className="project-card">
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography
            variant="h6"
            data-active={!!active}
            className="project-title"
          >
            {active && <LaptopMacIcon className="icon" color="inherit" />}
            {project.project_title}
          </Typography>
          <Typography variant="body1">{project.task_title}</Typography>
          <Typography variant="caption">
            Duration&nbsp;:&nbsp;
            <Typography component="span" variant="body2">
              {formatSeconds(project.duration)}
            </Typography>
          </Typography>
        </Grid>
        <Grid item className="project-actions">
          <Box>
            {active && <StopTask />}
            {!active && project.project_title && project.task_title && (
              <StartTask
                projectName={project.project_title}
                taskName={project.task_title}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

function formatSeconds(seconds?: number) {
  if (seconds === 0) return "00:00";
  if (!seconds) return "";
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minuts = Math.floor(seconds / 60);
  seconds -= minuts * 60;
  return `${hours}:${minuts < 10 ? "0" : ""}${minuts}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;
}
