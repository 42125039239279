import React from "react";
import { useAppState } from "../states/app-state";
import { useHistory } from "react-router-dom";

const Logout: React.FC = () => {
  const authState = useAppState();
  const history = useHistory();

  React.useEffect(() => {
    if (authState.loggedin) {
      authState.logout();
      history.push("/");
    }
  }, [authState, history]);

  return <div>Logging out...</div>;
};

export default Logout;
