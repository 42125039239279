import { createTheme, PaletteType } from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

function createDarkPalette(): PaletteOptions {
  return {
    type: "dark",
    primary: {
      main: "#222",
      light: "",
      dark: "#333",
    },
    secondary: {
      main: "#0097a7",
      light: "#33abb8",
      dark: "#006974",
    },
    error: {
      main: "#e53935",
      dark: "#a02725",
      light: "#ea605d",
    },
  };
}

function createLightPalette(): PaletteOptions {
  return {
    type: "light",
    primary: {
      main: "#f3e6db",
      light: "",
      dark: "#aaa",
    },
    secondary: {
      main: "#0097a7",
      light: "#33abb8",
      dark: "#006974",
    },
    error: {
      main: "#e53935",
      dark: "#a02725",
      light: "#ea605d",
    },
  };
}

const createAppTheme = (type: PaletteType) =>
  createTheme({
    palette: type === "light" ? createLightPalette() : createDarkPalette(),
    overrides: {
      MuiInputBase: {
        root: {
          // fontSize: 16,
        },
      },
      MuiCssBaseline: {
        "@global": {
          // html: {
          //   backgroundColor: '#0082C8',
          // },
          // body: {
          //   backgroundColor: '#0082C8',
          // },
        },
      },
    },
  });

export default createAppTheme;
