export function InvemoLogo() {
  return (
    <svg
      width="116"
      height="30"
      viewBox="0 0 116 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.7933 11.0544H0.759781V29.966H4.7933V11.0544Z"
        fill="#F58426"
      />
      <path
        d="M70.0335 11.1111L66.1229 11.1338V29.966H70.0447V20.9751C70.0447 18.1179 70.391 14.2517 74.581 14.2517C78.2011 14.2517 78.3352 17.8912 78.3352 20.2721V29.966H82.257V20.9751C82.257 18.1633 82.5586 14.2517 86.7486 14.2517C90.1899 14.2517 90.5475 17.2676 90.5475 19.6145V29.9773H94.4693V19.0249C94.4693 14.8753 93.0726 11.1225 87.7095 11.1225C85.1396 11.1225 82.6927 12.415 81.4301 14.3651C80.1229 12.3356 78.2011 11.1225 75.5419 11.1225C73.3184 11.1225 71.162 12.1655 70.0335 13.7642L70.0447 11.6667"
        fill="#F58426"
      />
      <path
        d="M29.6648 11.0771H25.1396L35.6536 30L46.2346 11.0771H41.7095L35.6648 22.8458L29.6648 11.0771Z"
        fill="#F58426"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M106.101 11.0658C100.559 11.0658 96.2011 15.1701 96.2011 20.5329C96.2011 25.8957 100.559 30 106.101 30C111.642 30 116 25.8957 116 20.5329C116.011 15.1701 111.654 11.0658 106.101 11.0658ZM106.101 14.3424C109.631 14.3424 112.436 17.1542 112.436 20.5442C112.436 23.8889 109.62 26.746 106.101 26.746C102.581 26.746 99.7653 23.9002 99.7653 20.5442C99.7653 17.1542 102.581 14.3424 106.101 14.3424Z"
        fill="#F58426"
      />
      <path
        d="M7.31845 11.0884L7.28493 29.9433H11.3184V21.4853C11.3184 18.1406 11.3184 14.127 16.514 14.127C20.6369 14.127 21.1285 16.2585 21.1285 19.3991V29.9433H25.162V18.9683C25.162 14.4785 23.6201 11.0884 17.7095 11.0884C15.1508 11.0884 12.581 11.746 11.3184 13.7188V11.0884"
        fill="#F58426"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M64.0559 21.0431V20.5102C64.0559 15.2268 61.095 11.0544 54.7821 11.0544C48.391 11.0544 45.1285 15.1587 45.1285 20.6236C45.1285 25.8617 48.8156 29.966 54.8715 29.966C58.7262 29.966 62.2011 28.1746 64.067 25.102L60.8492 23.4694C59.4078 25.6009 57.8436 26.9274 54.9162 26.9274C51.2737 26.9274 48.9832 24.0816 48.9385 21.0431H64.0559ZM49.1508 18.4581C49.6201 15.9184 51.8212 14.0136 54.7821 14.0136C57.8324 14.0136 59.7765 15.7937 60.2458 18.4581H49.1508Z"
        fill="#F58426"
      />
      <path
        d="M2.77095 0C1.2514 0 0 1.12245 0 2.52835C0 3.88889 1.2514 4.97732 2.77095 4.97732C4.2905 4.97732 5.5419 3.88889 5.5419 2.52835C5.55307 1.12245 4.30167 0 2.77095 0Z"
        fill="#F58426"
      />
    </svg>
  );
}
